import React from "react"
import { ImageHome, ImageContainer } from "../components/image/Image.js"
import imgServ1 from "../static/img5.png"
import { Heading, Subtitle } from "../components/title/Title.js"
import { Row } from "../components/row/Row.js"
import { Section } from '../components/section/Section';
import { Contact, ButtonContainer } from "../components/button/Button.js"
import { Column50, Column100 } from '../components/column/Column.js'
import { TextMedium } from "../components/text/Text.js"

function CtaBlock() {
  return (
    <Section isLight isCentered>
      <Row isCentered>
        <Column100 isCentered>
          <Heading heading="Want to work with us?" />
          <TextMedium textMedium="Tell us about your project and what you need help with." />
          <ButtonContainer isCentered>
            <Contact buttonText="Get in touch" buttonLink="/contact/" />
          </ButtonContainer>
        </Column100>
      </Row>
    </Section>
  )
}

export default CtaBlock
