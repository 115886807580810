import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout/Layout.js"
import Seo from "../components/seo"
import { HeroSection, Section, BelowFold } from "../components/section/Section.js"
import { Row } from "../components/row/Row"
import { Testimonials } from "../components/testimonial/Testimonial.js"
import {
  Heading,
} from "../components/title/Title.js"
import { Column50 } from "../components/column/Column.js"
import featuredImage from "../static/homehero.png"
import Services from "../blocks/Services.js"
import { Contact, ButtonContainer } from "../components/button/Button.js"
import homeRight from "../static/homehero.png"
import {ImageHome} from "../components/image/Image.js"
import Trusted from "../blocks/Trusted"
import CtaBlock from "../blocks/CtaBlock"
import { Numbers } from "../components/numbers/Numbers.js"
import { TextMedium } from "../components/text/Text.js"


function Consulting({ children, ...rest }) {

  const data = useStaticQuery(graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    allContentfulCaseStudy(limit:1, sort: { fields: date, order: DESC }){
      edges {
        node {
          title
          featuredImage{
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
            resize(height: 630, width: 1200) {
              src
            }
          }
          excerpt
          slug
          description {
            childMarkdownRemark {
              timeToRead
            }
          }
          date(formatString: "Do MMMM, YYYY")
          id
        }
      }
      totalCount
    }
  }
`)

  return (
    <Layout>
      <Seo title="School of Content - Content Strategy Consulting" 
      description="Know what content to create, where to distribute it and how to
             manage it with a clear content strategy."
       image={featuredImage}
       featuredImage={featuredImage}/>
      
      <HeroSection 
        title="Content strategy consulting for SaaS" 
        text="Startup or scaleup - your content needs to drive revenue. We help you build a system that ensures predictable, consistent growth." 
        buttonText="See prices" 
        buttonLink="/prices/"
        imageUrl={homeRight}
        imageAlt="Content strategy consulting"
      />

      <Trusted />

      <Services />

      <Numbers isLight 
        numbersHeading="The numbers speak for themselves" 
        numbersSubheading="By following my methodology, my clients and mentees are experiencing consistent growth."
        impactNumber1="+49.8%" explanation1="Avg. impressions QoQ"
        impactNumber2="+43.4%" explanation2="Avg. clicks QoQ"
        impactNumber3="+11.3%" explanation3="Avg. conversions QoQ"
        impactNumber4="15.9%" explanation4="Avg. lead:opp ratio"
        />

      <Section>
        <Row isCentered>
          {data.allContentfulCaseStudy.edges.map(({ node }) => (
            <>
            <Column50 key={node.id}>
              <ImageHome src={node.featuredImage.resize.src} alt="homepage" />
            </Column50>
            <Column50 key={node.id}>
              <TextMedium textMedium="Featured case study" />
              <Heading heading={`${node.title}`} />
              <TextMedium textMedium={`${node.excerpt}`}/>
              <ButtonContainer>
                <Contact
                  buttonText="Read the case"
                  buttonLink={`/case-studies/${node.slug}/`}
                  />
              </ButtonContainer>
            </Column50>
            </>
          ))}
        </Row>
      </Section>

      <Testimonials/>

      <CtaBlock />

      </Layout>
  )
}

export default Consulting
