import React from "react"
import { Row } from "../components/row/Row.js"
import { Column50 } from "../components/column/Column.js"
import { Section } from "../components/section/Section.js"
import { Heading, Subtitle } from "../components/title/Title.js"
import { TextMedium } from "../components/text/Text.js"
import { Link } from "../components/link/Link.js"
import { ButtonContainer } from "../components/button/Button"
import { ImageHome } from "../components/image/Image.js"
import img1 from '../static/contentstrategy.png'
import img2 from '../static/contentaudit.png'

function Services() {
  return (
    <Section isCentered>
      <Row isCentered>
        <Column50>
          <Subtitle isPrimary subtitle="Content strategy" />
          <TextMedium textMedium="We define your content goals, your target audience and your ideal distribution channels, and develop the high-level content strategy. 
          Based on keyword research, analytics and CRM data, we define your funnels, content calendar, KPIs and implementation plan." />
          <ButtonContainer>
            <Link href="/content-strategy/" anchortext="See how we work" />
          </ButtonContainer>
        </Column50>
        <Column50 isFloating>
          <ImageHome src={img1} alt="contentstrategy" />
        </Column50>
      </Row>
      <Row isCentered>
        <Column50>
          <ImageHome src={img2} alt="contentstrategy" />
        </Column50>
        <Column50>
          <Subtitle isPrimary subtitle="Content audit" />
          <TextMedium textMedium="We perform a content audit and inventory to get a clear overview of what's working and where your gaps are. 
          We analyze your distribution strategy and your competitors and define your content roadmap based on your business and marketing goals." />
          <ButtonContainer>
            <Link href="/content-audit/" anchortext="See how we work" />
          </ButtonContainer>
        </Column50>
      </Row>
    </Section>
  )
}

export default Services
