import styled from "styled-components"
import { colors, backgrounds } from "../../styles/colors.js"
import { sizes } from "../../styles/sizes"
import { fontSizes } from "../../styles/typography"

export const NumbersWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: ${props => (props.isCentered ? "center" : "flex-start")};
  align-items: center;
  padding: ${sizes.sizeXXXL} ${sizes.sizeXXXXL};
  margin: 50px auto;
  border-radius: 8px;
  background-color: ${props =>
    props.isLight ? `${backgrounds.bgPinkLightest}` : `${colors.colorWhite}`};
  color: ${colors.colorTextDark};
  width: auto;
`

export const NumbersHeading = styled.h3`
  font-size: ${fontSizes.fontL};
  margin-bottom: ${sizes.sizeL};
  line-height: ${fontSizes.fontL};
  color: ${colors.colorTextPurple};
`

export const NumbersSubheading = styled.p`
  font-size: ${fontSizes.fontM};
  margin-bottom: ${sizes.sizeL};
  line-height: ${fontSizes.fontL};
  color: ${colors.colorTextPurple};
`

export const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;
  width: 100%;
`

export const NumbersDiv = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: auto;
`

export const ImpactNumber = styled.h4`
  font-size: ${fontSizes.fontL};
  margin-bottom: 0;
  font-weight: 600;
  color: ${colors.colorTextPurple};
`

export const Explanation = styled.p`
  font-size: ${fontSizes.fontS};
`
