import React from "react"
import {
    NumbersWrapper,
    NumbersHeading,
    NumbersSubheading,
    NumbersDiv,
    ImpactNumber,
    Explanation,
    Wrapper
  } from "./style.js"



export const Numbers = ({ ...props }) => (
    <NumbersWrapper isLight={props.isLight} isCentered={props.isCentered}>
        <NumbersHeading>{props.numbersHeading}</NumbersHeading>
        <NumbersSubheading>{props.numbersSubheading}</NumbersSubheading>
        <Wrapper>
            <NumbersDiv>
                <ImpactNumber>{props.impactNumber1}</ImpactNumber>
                <Explanation>{props.explanation1} </Explanation>
            </NumbersDiv>
            <NumbersDiv>
                <ImpactNumber>{props.impactNumber2}</ImpactNumber>
                <Explanation>{props.explanation2} </Explanation>
            </NumbersDiv>
            <NumbersDiv>
                <ImpactNumber>{props.impactNumber3}</ImpactNumber>
                <Explanation>{props.explanation3} </Explanation>
            </NumbersDiv>
            <NumbersDiv>
                <ImpactNumber>{props.impactNumber4}</ImpactNumber>
                <Explanation>{props.explanation4} </Explanation>
            </NumbersDiv>
        </Wrapper>
    </NumbersWrapper>
)
